import React from 'react'
import { Link } from 'gatsby'
import { TARGET_CLASS } from '../../utils/visible'
import { multiAuthors } from '../../../gatsby-meta-config'
import { rhythm } from '../../utils/typography'

import './index.scss'

export const ThumbnailItem = ({ node }) => {
  var { author } = multiAuthors[Number(node.frontmatter.authorID)]

  const getCategoryName = () => {
    return (
      String(node.frontmatter.category)
        .charAt(0)
        .toUpperCase() + String(node.frontmatter.category).slice(1)
    )
  }

  return (
    <Link
      className={`thumbnail ${TARGET_CLASS}`}
      to={node.fields.slug}
      style={{
        boxShadow: '0 1px 6px rgba(57, 73, 76, 0.35)',
        marginBottom: 30,
        padding: 0,
        backgroundColor: '#fff',
      }}
    >
      <h2
        style={{
          backgroundColor: '#f9f9f9',
          margin: `0 -${rhythm(0)}`,
          padding: `${rhythm(0.8)} `,
          borderBottom: '0.5px solid #e0e0e0',
        }}
      >
        {node.frontmatter.title || node.fields.slug}
      </h2>
      <div
        key={node.fields.slug}
        style={{ padding: `${rhythm(0.6)} ${rhythm(1)} ` }}
      >
        <div
          style={{
            flexDirection: 'row',
            display: 'inline-flex',
            width: '100%',
            marginBottom: '10px',
            alignItems: 'center',
          }}
        >
          <div style={{ marginTop: 0, marginBottom: 0, flex: 1 }}>
            <h6
              style={{
                color: '#EC1C24',
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              {'By : '}
              {author}
            </h6>
          </div>

          <div style={{ marginTop: 0, marginBottom: 0, flex: 1 }}>
            <h6
              style={{
                color: '#EC1C24',
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              {'Category : '}
              {getCategoryName()}
            </h6>
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <svg
              height="24px"
              viewBox="0 0 512 512"
              width="24px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m416 512h-320c-53.023438 0-96-42.976562-96-96v-320c0-53.023438 42.976562-96 96-96h320c53.023438 0 96 42.976562 96 96v320c0 53.023438-42.976562 96-96 96zm0 0"
                fill="#ffe6e2"
              />

              <path
                d="m352 160h-192c-17.648438 0-32 14.351562-32 32v160c0 17.648438 14.351562 32 32 32h192c17.648438 0 32-14.351562 32-32v-160c0-17.648438-14.351562-32-32-32zm10.671875 192c0 5.886719-4.785156 10.671875-10.671875 10.671875h-192c-5.886719 0-10.671875-4.785156-10.671875-10.671875v-116.910156h213.328125v116.910156zm0 0"
                fill="#fc573b"
              />

              <g fill="#fd907e">
                <path d="m192 128h-10.671875c-5.886719 0-10.671875 4.769531-10.671875 10.671875v42.671875c0 5.886719 4.769531 10.671875 10.671875 10.671875h10.671875c5.886719 0 10.671875-4.769531 10.671875-10.671875v-42.671875c0-5.902344-4.785156-10.671875-10.671875-10.671875zm0 0" />
                <path d="m330.671875 128h-10.671875c-5.886719 0-10.671875 4.769531-10.671875 10.671875v42.671875c0 5.886719 4.785156 10.65625 10.671875 10.65625h10.671875c5.886719 0 10.671875-4.769531 10.671875-10.671875v-42.65625c-.015625-5.902344-4.785156-10.671875-10.671875-10.671875zm0 0" />
              </g>
            </svg>
            <h6
              style={{
                color: '#EC1C24',
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 10,
              }}
            >
              {node.frontmatter.date}
            </h6>
          </div>
        </div>

        <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
      </div>
    </Link>
  )
}
